import axios from "axios";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
function App() {
  const [step, setStep] = useState(0);

  const [formData, setFormData] = useState({
    fullname: "",
    phone: "",
    email: "",
    CrawlFrom: "",
    saler: "",
    maketer: "",
  });

  const handleSubmit = () => {
    const department = document.URL.replace(
      /.*department=([^&]*).*|(.*)/,
      "$1"
    );
    if (department === "sale") {
      delete formData.maketer;
    }
    if (department === "marketing") {
      delete formData.saler;
    } 
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(emailPattern.test(formData?.email)){
      if (
        formData.fullname.length > 0 &&
        formData.email.length > 0 &&
        formData.phone.length > 0 
      ) {
        axios
          .post(
            "https://api-crm.netpartner.com.vn/customers/customer-fill-to-form",
            formData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            const body = res.data;
            if (body.statusCode === 200) {
              toast.success("Điền thông tin thành công", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
              });
              setStep(1);
            } else {
              toast.error(body.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
              });
            }
          });
      }
    } else {
      toast.info("Vui lòng điền đúng email!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }

  };

  useEffect(() => {
    const user_code = document.URL.replace(/.*user_code=([^&]*).*|(.*)/, "$1");
    const from = document.URL.replace(/.*from=([^&]*).*|(.*)/, "$1");
    setFormData((curr) => ({
      ...curr,
      CrawlFrom: from,
      saler: user_code,
      maketer: user_code,
    }));
  }, []);

  // http://localhost:3000/gioi-thieu-khach-hang?user_code=AEC001&department=sale&from=dooprime
  return (
    <div className="">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <h1 className="text-white text-3xl font-bold uppercase bg-red-800 text-center py-12">
        Đăng ký
      </h1>

      <div className="flex justify-center">
        <div className="flex space-x-16 mt-8 relative">
          <div className="w-96 absolute top-20 right-24 h-2 bg-gray-300 z-0"></div>
          <div
            className={`p-16 ${
              step === 0
                ? "bg-gradient-to-r from-[#a11828] to-[#981d47] text-white"
                : "bg-gray-300 text-gray-700"
            } w-40 h-40 rounded-full flex justify-center items-center z-10`}
          >
            <div className="text-center">
              <p className="text-3xl w-40">Bước 1</p>
              <span className="text-sm w-40">Đăng ký</span>
            </div>
          </div>

          <div
            className={`p-16 ${
              step === 1
                ? "bg-gradient-to-r from-[#a11828] to-[#981d47] text-white"
                : "bg-gray-300 text-gray-700"
            } w-40 h-40 rounded-full flex justify-center items-center z-10`}
          >
            <div className="text-center">
              <p className="text-3xl w-40">Bước 2</p>
              <span className="text-sm w-40">Thông tin cá nhân</span>
            </div>
          </div>

          <div
            className={`p-16 ${
              step === 2
                ? "bg-gradient-to-r from-[#a11828] to-[#981d47]"
                : "bg-gray-300 text-gray-00"
            } w-40 h-40 rounded-full flex justify-center items-center z-10`}
          >
            <div className="text-center">
              <p className="text-3xl w-40">Bước 3</p>
              <span className="text-sm w-40">Hoàn tất đăng ký</span>
            </div>
          </div>
        </div>
      </div>

      {step === 0 && (
        <div className="w-1/3 mx-auto mt-16 space-y-6">
          <p className="text-sm">Phần có dấu * là bắt buộc</p>

          <div className="space-y-2">
            <label className="block text-sm">
              Quốc gia cư trú <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="border w-full bg-gray-200 p-2 border rounded"
              disabled
              value="Vietnam"
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm">
              Họ và tên <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="border w-full p-2 border rounded"
              onChange={(e) =>
                setFormData((curr) => ({ ...curr, fullname: e.target.value }))
              }
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm">
              Số điện thoại <span className="text-red-500">*</span>
            </label>
            <div className="flex">
              <input
                type="text"
                value="+84"
                className="border w-32 p-2 border rounded"
              />
              <input
                type="text"
                className="border w-full p-2 border rounded"
                onChange={(e) =>
                  setFormData((curr) => ({ ...curr, phone: e.target.value }))
                }
              />
            </div>
          </div>

          <div className="space-y-2">
            <label className="block text-sm">
              Email <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              className="border w-full p-2 border rounded"
              onChange={(e) =>
                setFormData((curr) => ({ ...curr, email: e.target.value }))
              }
            />
          </div>

          <button
            className="text-sm text-center w-full bg-gradient-to-r from-[#85b353] to-[#74a83a] text-white font-semibold py-2 rounded"
            onClick={handleSubmit}
          >
            Tiếp theo
          </button>
        </div>
      )}

      {step === 1 && (
        <div className="w-1/3 mx-auto mt-16 space-y-6 text-center">
          <p className="text-3xl font-semibold text-gray-700">
            Xác nhận đăng ký của bạn
          </p>
          <p className="text-xl text-gray-700 leading-loose">
            Nhấn vào nút mà chúng tôi đã gửi email tới <br></br>{" "}
            {`${formData.email} để tiếp tục đăng ký`} <br></br>
            Vui lòng kiểm tra hộp thư rác nếu bạn không thể tìm thấy email
            <br></br>
            trong hộp thư đến của mình
            <br></br>
            Trân trọng!
          </p>
        </div>
      )}
    </div>
  );
}

export default App;
